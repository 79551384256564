.container {
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 1.25rem;
}

.clickable {
  color: red;
  text-decoration: unsderline;
  font-style: bold;
  cursor: pointer;
}

.edited {
  color: black;
  text-decoration: none;
  color: blue;
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5vw;
}

.logos img {
  width: 10vw;
}

.logos label {
  display: flex;
  flex-direction: column;
}

input {
  width: 10vw;
}

textarea {
  width: 50vw;
}

.container button {
  width: 200px;
}

textarea, input[type="text"] {
  padding: 5px;
  margin: 5px 0;
  box-sizing: border-box;
}

textarea {
  resize: vertical; /* Allow vertical resizing */
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

p {
  margin-bottom: 15px;
}

.export {
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

button {
  padding: 10px 15px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

button:hover {
  background-color: #0056b3;
}